export const colors = {
  apple10: "rgb(255, 237, 222)",
  apple20: "rgb(255, 214, 205)",
  apple30: "rgb(255, 182, 163)",
  apple40: "rgb(253, 124, 98)",
  apple50: "rgb(249, 81, 62)",
  apple60: "rgb(221, 34, 20)",
  apple70: "rgb(136, 12, 17)",
  apple80: "rgb(93, 9, 9)",
  apple90: "rgb(57, 9, 9)",
  bwAbsoluteBlack: "rgb(0, 0, 0)",
  bwAbsoluteWhite: "rgb(255, 255, 255)",
  bwBlack: "rgb(0, 0, 0)",
  bwWhite: "rgb(255, 255, 255)",
  backgroundAccent: "rgb(245, 235, 255)",
  backgroundDanger: "rgb(255, 214, 205)",
  backgroundDisabled: "rgba(93, 93, 143, 10%)",
  backgroundMedia: "rgba(44, 42, 80, 60%)",
  backgroundPrimary: "rgb(255, 255, 255)",
  backgroundSecondary: "rgb(241, 243, 248)",
  backgroundSkrim: "rgba(26, 25, 45, 80%)",
  backgroundSuccess: "rgb(199, 255, 234)",
  backgroundTertiary: "rgb(226, 228, 240)",
  bordersActive: "rgb(125, 64, 255)",
  bordersDecorative: "rgba(0, 9, 91, 10%)",
  bordersDisabled: "rgba(31, 21, 81, 15%)",
  bordersPrimary: "rgba(0, 9, 91, 45%)",
  boxShadowColor: "rgba(45, 64, 150, 6%)",
  brandColorsDojoBrand: "rgb(125, 64, 255)",
  brandColorsPlusBrand: "rgb(128, 71, 255)",
  brandColorsTutorBrand: "rgb(94, 158, 255)",
  cloud10: "rgb(241, 243, 248)",
  cloud20: "rgb(226, 228, 240)",
  cloud30: "rgb(202, 206, 232)",
  cloud40: "rgb(170, 176, 216)",
  cloud50: "rgb(134, 137, 184)",
  cloud60: "rgb(93, 93, 143)",
  cloud70: "rgb(64, 63, 108)",
  cloud80: "rgb(44, 42, 80)",
  cloud90: "rgb(26, 25, 45)",
  contentAccent: "rgb(125, 64, 255)",
  contentAccentHover: "rgb(97, 43, 212)",
  contentDanger: "rgb(221, 34, 20)",
  contentDangerHover: "rgb(136, 12, 17)",
  contentDisabled: "rgb(202, 206, 232)",
  contentLegacyAccent: "rgb(0, 146, 229)",
  contentLight: "rgb(255, 255, 255)",
  contentPrimary: "rgb(26, 25, 45)",
  contentSecondary: "rgb(93, 93, 143)",
  contentSuccess: "rgb(19, 128, 58)",
  contentTertiary: "rgb(134, 137, 184)",
  fox10: "rgb(255, 238, 219)",
  fox20: "rgb(255, 223, 179)",
  fox30: "rgb(255, 192, 134)",
  fox40: "rgb(251, 146, 70)",
  fox50: "rgb(241, 92, 0)",
  fox60: "rgb(185, 65, 14)",
  fox70: "rgb(128, 38, 11)",
  fox80: "rgb(84, 23, 8)",
  fox90: "rgb(56, 16, 8)",
  frog10: "rgb(224, 255, 245)",
  frog20: "rgb(199, 255, 234)",
  frog30: "rgb(135, 240, 196)",
  frog40: "rgb(70, 205, 143)",
  frog50: "rgb(38, 159, 98)",
  frog60: "rgb(19, 128, 58)",
  frog70: "rgb(10, 94, 28)",
  frog80: "rgb(7, 67, 11)",
  frog90: "rgb(11, 51, 8)",
  grape10: "rgb(245, 235, 255)",
  grape20: "rgb(240, 217, 255)",
  grape30: "rgb(223, 189, 255)",
  grape40: "rgb(195, 153, 255)",
  grape50: "rgb(157, 112, 255)",
  grape60: "rgb(125, 64, 255)",
  grape70: "rgb(97, 43, 212)",
  grape80: "rgb(65, 36, 143)",
  grape90: "rgb(31, 21, 81)",
  ocean10: "rgb(235, 242, 255)",
  ocean20: "rgb(204, 227, 255)",
  ocean30: "rgb(173, 210, 255)",
  ocean40: "rgb(128, 178, 255)",
  ocean50: "rgb(58, 127, 231)",
  ocean60: "rgb(27, 99, 192)",
  ocean70: "rgb(25, 82, 148)",
  ocean80: "rgb(14, 60, 103)",
  ocean90: "rgb(11, 46, 71)",
  pig10: "rgb(255, 232, 247)",
  pig20: "rgb(255, 214, 238)",
  pig30: "rgb(255, 189, 225)",
  pig40: "rgb(255, 143, 208)",
  pig50: "rgb(248, 109, 192)",
  pig60: "rgb(195, 65, 147)",
  pig70: "rgb(143, 33, 105)",
  pig80: "rgb(100, 16, 74)",
  pig90: "rgb(70, 9, 53)",
  sky10: "rgb(235, 249, 255)",
  sky20: "rgb(204, 244, 255)",
  sky30: "rgb(173, 237, 255)",
  sky40: "rgb(128, 221, 255)",
  sky50: "rgb(73, 180, 216)",
  sky60: "rgb(27, 154, 192)",
  sky70: "rgb(25, 124, 148)",
  sky80: "rgb(14, 90, 103)",
  sky90: "rgb(11, 66, 71)",
  sun10: "rgb(255, 253, 223)",
  sun20: "rgb(255, 247, 178)",
  sun30: "rgb(255, 238, 128)",
  sun40: "rgb(255, 214, 51)",
  sun50: "rgb(255, 184, 0)",
  sun60: "rgb(201, 124, 1)",
  sun70: "rgb(147, 82, 2)",
  sun80: "rgb(103, 51, 5)",
  sun90: "rgb(71, 33, 9)",
};
export interface ColorsType {
  apple10: string;
  apple20: string;
  apple30: string;
  apple40: string;
  apple50: string;
  apple60: string;
  apple70: string;
  apple80: string;
  apple90: string;
  bwAbsoluteBlack: string;
  bwAbsoluteWhite: string;
  bwBlack: string;
  bwWhite: string;
  backgroundAccent: string;
  backgroundDanger: string;
  backgroundDisabled: string;
  backgroundMedia: string;
  backgroundPrimary: string;
  backgroundSecondary: string;
  backgroundSkrim: string;
  backgroundSuccess: string;
  backgroundTertiary: string;
  bordersActive: string;
  bordersDecorative: string;
  bordersDisabled: string;
  bordersPrimary: string;
  boxShadowColor: string;
  brandColorsDojoBrand: string;
  brandColorsPlusBrand: string;
  brandColorsTutorBrand: string;
  cloud10: string;
  cloud20: string;
  cloud30: string;
  cloud40: string;
  cloud50: string;
  cloud60: string;
  cloud70: string;
  cloud80: string;
  cloud90: string;
  contentAccent: string;
  contentAccentHover: string;
  contentDanger: string;
  contentDangerHover: string;
  contentDisabled: string;
  contentLegacyAccent: string;
  contentLight: string;
  contentPrimary: string;
  contentSecondary: string;
  contentSuccess: string;
  contentTertiary: string;
  fox10: string;
  fox20: string;
  fox30: string;
  fox40: string;
  fox50: string;
  fox60: string;
  fox70: string;
  fox80: string;
  fox90: string;
  frog10: string;
  frog20: string;
  frog30: string;
  frog40: string;
  frog50: string;
  frog60: string;
  frog70: string;
  frog80: string;
  frog90: string;
  grape10: string;
  grape20: string;
  grape30: string;
  grape40: string;
  grape50: string;
  grape60: string;
  grape70: string;
  grape80: string;
  grape90: string;
  ocean10: string;
  ocean20: string;
  ocean30: string;
  ocean40: string;
  ocean50: string;
  ocean60: string;
  ocean70: string;
  ocean80: string;
  ocean90: string;
  pig10: string;
  pig20: string;
  pig30: string;
  pig40: string;
  pig50: string;
  pig60: string;
  pig70: string;
  pig80: string;
  pig90: string;
  sky10: string;
  sky20: string;
  sky30: string;
  sky40: string;
  sky50: string;
  sky60: string;
  sky70: string;
  sky80: string;
  sky90: string;
  sun10: string;
  sun20: string;
  sun30: string;
  sun40: string;
  sun50: string;
  sun60: string;
  sun70: string;
  sun80: string;
  sun90: string;
}
