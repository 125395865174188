import axios from "axios";
import getIdeasApiURL from "@src/services/ideas/getIdeasApiURL";

async function applyToBeMentor(application: {
  birthday?: string;
  intentions?: string;
  shareWith?: string;
  agreement: true;
  questions?: { questionText: string; answer: string }[];
}) {
  const path = `/api/mentorApplication`;

  return await axios.post(`${getIdeasApiURL()}${path}`, application);
}

export default applyToBeMentor;
