export const localesList = {
  "en-US": "English",
  "en-GB": "English (UK)",
  "id-ID": "Bahasa Indonesia",
  "ca-ES": "Català",
  "de-DE": "Deutsch",
  "es-MX": "Español (Latinoamérica)",
  "es-ES": "Español (España)",
  "fr-CA": "Français (Canada)",
  "fr-FR": "Français",
  "pl-PL": "Polski",
  "pt-BR": "Português (Brasil)",
  "pt-PT": "Português (Portugal)",
  "fil-PH": "Filipino",
  "vi-VN": "Tiếng Việt",
  "th-TH": "ไทย",
  "tr-TR": "Türkçe",
  "ru-RU": "Русский",
  "uk-UA": "Українська",
  "ar-AR": "العربية",
  "hi-IN": "हिन्दी",
  "ko-KR": "한국어",
  "zh-CN": "中文(简体)",
  "ja-JP": "日本語",
} as const;
