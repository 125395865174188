import React, { useState } from "react";
import { Flex } from "@src/components/Boxes";
import styled from "@emotion/styled";
import { graphql, useStaticQuery } from "gatsby";
import { css, Global } from "@emotion/react";
import normalize from "@src/styles/normalize";
import theme from "@src/styles/theme";
import { ThemeProvider } from "@emotion/react";
import Helmet from "react-helmet";
import { generatingFor } from "@src/utils/routes";
import { TranslationProvider } from "@src/components/translation/TranslationContext";
import { MobileDataContextProvider } from "@src/components/mobile/MobileDataContext";
import StyledLayoutMain from "@src/layouts/common/StyledLayoutMain";
import OneTrustScript from "@src/components/partials/shared/OneTrustScript";
import * as S from "../../components/Header/styles";
import LanguageChooserModal from "@src/components/modals/LanguageChooserModal";
import Translate from "@src/components/translation/Translate";
import { DevTools } from "@src/components/devTools";

const SimpleHeader = styled("nav")`
  height: 50px;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
  padding: 1rem;
  border-width: 0;
  z-index: 1000;
`;

function MobileLayout({ children, pageContext }) {
  const [showModal, setShowModal] = useState(false);
  generatingFor.locale = pageContext.localeStrings.currentLocale;
  const data = useStaticQuery(graphql`
    {
      logo: file(name: { eq: "classdojo_logo_176@2x" }) {
        childImageSharp {
          gatsbyImageData(width: 400, placeholder: NONE, layout: CONSTRAINED)
        }
      }
    }
  `);

  const { logo } = data;

  return (
    <>
      <Helmet htmlAttributes={{ lang: pageContext.localeStrings.currentLocale }}>
        <link rel="stylesheet" href="https://static.classdojo.com/fonts/proxima/proximanova-default-stack.css" />
        <title>ClassDojo Mobile Web</title>
      </Helmet>
      <OneTrustScript />
      <Global
        styles={css`
          ${normalize};
          #___gatsby,
          #gatsby-focus-wrapper,
          main,
          html,
          body {
            height: 100%;
          }
        `}
      />
      <TranslationProvider localeStrings={pageContext.localeStrings}>
        <ThemeProvider theme={theme}>
          <MobileDataContextProvider>
            <StyledLayoutMain id="main-page-container">
              <SimpleHeader>
                <Flex height="100%" alignItems="center">
                  <S.Logo
                    src={logo.childImageSharp.gatsbyImageData.images.fallback.src}
                    loading="eager"
                    alt="ClassDojo logo"
                  />
                </Flex>
              </SimpleHeader>
              {children}
              {showModal ? (
                <LanguageChooserModal closeModal={() => setShowModal(false)} />
              ) : (
                <div style={{ textAlign: "center" }}>
                  <LanguageButton onClick={() => setShowModal(true)}>
                    <Translate locale />
                    <Carret />
                  </LanguageButton>
                </div>
              )}
            </StyledLayoutMain>
            <DevTools mobile />
          </MobileDataContextProvider>
        </ThemeProvider>
      </TranslationProvider>
    </>
  );
}

export default MobileLayout;

const LanguageButton = styled("button")`
  padding: 10px 20px;
  font-size: 14px;
  font-weight: 400;
  text-align: left;
  border: 0;
  border-radius: 3px;
  width: 100%;
  vertical-align: middle;
  cursor: pointer;
  background-image: none;
  white-space: nowrap;
  line-height: 1.42857143;
  max-width: 400px;
`;

const Carret = styled.span`
  width: 0;
  height: 0;
  margin-left: 2px;
  vertical-align: middle;
  border-top: 4px solid;
  border-right: 4px solid transparent;
  border-left: 4px solid transparent;
  float: right;
  margin-top: 8px;
`;
