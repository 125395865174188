import midnightRefresh from "@src/utils/midnightRefresh";

/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */
global.FlexSearch = require("flexsearch");

export const onClientEntry = () => {
  // IntersectionObserver polyfill for gatsby-background-image (Safari, IE)
  if (typeof window.IntersectionObserver === `undefined`) {
    import(`intersection-observer`);
    console.log(`# IntersectionObserver is polyfilled!`);
  }

  if (process.env.GATSBY_BUILD_WEBSITE.indexOf("blog") > -1) {
    // load json data into window variable
    fetch(`${__PATH_PREFIX__}/flexsearch_index.json`)
      .then(function (response) {
        return response.json();
      })
      .then(function (index) {
        Object.keys(index).forEach((lng) => {
          Object.keys(index[lng].index).forEach((idx) => {
            const index_ = index[lng].index[idx];

            // load language files if needed by stemmer or filter
            if (index_.attrs.stemmer !== undefined || index_.attrs.filter !== undefined) {
              try {
                require("./src/creators/flexsearch-en");
              } catch (e) {
                console.error(e);
              }
            }
            // rebuild the index
            const indexObj = new FlexSearch(index_.attrs);
            indexObj.import(index_.values);
            index_.values = indexObj;
          });
        });
        // load index into window variable
        window.__FLEXSEARCH__ = index;
      })
      .catch(function (e) {
        console.error("Failed fetch search index");
      });
  }

  midnightRefresh();
};

function getDuration() {
  const start = window.start || new Date();
  const now = new Date();
  const difference = now.getTime() - start.getTime();

  if (difference === 0) {
    return null;
  }

  return difference;
}

let first = true;
export const onRouteUpdate = ({ location, prevLocation }, pluginOptions) => {
  if (!window._paq) return;

  const { _paq } = window;
  const url = location && location.pathname + location.search + location.hash;
  const prevUrl = prevLocation && prevLocation.pathname + prevLocation.search + prevLocation.hash;

  // document.title workaround stolen from:
  // https://github.com/gatsbyjs/gatsby/blob/master/packages/gatsby-plugin-google-analytics/src/gatsby-browser.js
  const sendPageView = () => {
    const { title } = document;

    prevUrl && _paq.push(["setReferrerUrl", prevUrl]);
    _paq.push(["setCustomUrl", url]);
    _paq.push(["setDocumentTitle", title]);
    _paq.push(["trackPageView"]);
    _paq.push(["enableLinkTracking"]);
    _paq.push(["trackAllContentImpressions"]);
  };

  if (first) {
    first = false;
    _paq.push(["trackEvent", "javascript", "load", "duration", getDuration()]);
  }

  // Minimum delay for reactHelmet's requestAnimationFrame
  setTimeout(sendPageView, 32);

  return null;
};
