import axios from "axios";
import getIdeasApiURL from "@src/services/ideas/getIdeasApiURL";

function shareToClassStory(targetId, body, image) {
  const path = `/api/storyFeed`;

  const r = axios.post(`${getIdeasApiURL()}${path}`, {
    targetId,
    targetType: "class",
    body,
    attachments: !image
      ? []
      : [
          {
            path: image,
            type: "photo",
          },
        ],
  });

  return r;
}

export default shareToClassStory;
