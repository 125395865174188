import axios from "axios";
import getIdeasApiURL from "@src/services/ideas/getIdeasApiURL";

async function getClasses() {
  const path = `/api/dojoClass`;
  const r = await axios.get(`${getIdeasApiURL()}${path}`);

  return r.data["_items"].map((classroom) => {
    return {
      _id: classroom._id,
      name: classroom.name,
    };
  });
}

export default getClasses;
