import React from "react";
import { NessieThemeProvider } from "@classdojo/web/nessie";
import * as NessieIcons from "@classdojo/web/nessie/icons";

export const ActivityIcon = (props: any) => {
  return (
    <NessieThemeProvider>
      <NessieIcons.ActivityIcon {...props} />
    </NessieThemeProvider>
  );
};

export const AddIcon = (props: any) => {
  return (
    <NessieThemeProvider>
      <NessieIcons.AddIcon {...props} />
    </NessieThemeProvider>
  );
};

export const AlertIcon = (props: any) => {
  return (
    <NessieThemeProvider>
      <NessieIcons.AlertIcon {...props} />
    </NessieThemeProvider>
  );
};

export const ArchiveIcon = (props: any) => {
  return (
    <NessieThemeProvider>
      <NessieIcons.ArchiveIcon {...props} />
    </NessieThemeProvider>
  );
};

export const ArrowLeftIcon = (props: any) => {
  return (
    <NessieThemeProvider>
      <NessieIcons.ArrowLeftIcon {...props} />
    </NessieThemeProvider>
  );
};

export const ArrowRightIcon = (props: any) => {
  return (
    <NessieThemeProvider>
      <NessieIcons.ArrowRightIcon {...props} />
    </NessieThemeProvider>
  );
};

export const AtomIcon = (props: any) => {
  return (
    <NessieThemeProvider>
      <NessieIcons.AtomIcon {...props} />
    </NessieThemeProvider>
  );
};

export const BookIcon = (props: any) => {
  return (
    <NessieThemeProvider>
      <NessieIcons.BookIcon {...props} />
    </NessieThemeProvider>
  );
};

export const CalendarIcon = (props: any) => {
  return (
    <NessieThemeProvider>
      <NessieIcons.CalendarIcon {...props} />
    </NessieThemeProvider>
  );
};

export const CallIcon = (props: any) => {
  return (
    <NessieThemeProvider>
      <NessieIcons.CallIcon {...props} />
    </NessieThemeProvider>
  );
};

export const CameraFlashIcon = (props: any) => {
  return (
    <NessieThemeProvider>
      <NessieIcons.CameraFlashIcon {...props} />
    </NessieThemeProvider>
  );
};

export const CameraIcon = (props: any) => {
  return (
    <NessieThemeProvider>
      <NessieIcons.CameraIcon {...props} />
    </NessieThemeProvider>
  );
};

export const CameraSwitchIcon = (props: any) => {
  return (
    <NessieThemeProvider>
      <NessieIcons.CameraSwitchIcon {...props} />
    </NessieThemeProvider>
  );
};

export const CameraVideoIcon = (props: any) => {
  return (
    <NessieThemeProvider>
      <NessieIcons.CameraVideoIcon {...props} />
    </NessieThemeProvider>
  );
};

export const CastIcon = (props: any) => {
  return (
    <NessieThemeProvider>
      <NessieIcons.CastIcon {...props} />
    </NessieThemeProvider>
  );
};

export const ChartIcon = (props: any) => {
  return (
    <NessieThemeProvider>
      <NessieIcons.ChartIcon {...props} />
    </NessieThemeProvider>
  );
};

export const CheckIcon = (props: any) => {
  return (
    <NessieThemeProvider>
      <NessieIcons.CheckIcon {...props} />
    </NessieThemeProvider>
  );
};

export const CheckboxEmptyIcon = (props: any) => {
  return (
    <NessieThemeProvider>
      <NessieIcons.CheckboxEmptyIcon {...props} />
    </NessieThemeProvider>
  );
};

export const CheckboxSelectedIcon = (props: any) => {
  return (
    <NessieThemeProvider>
      <NessieIcons.CheckboxSelectedIcon {...props} />
    </NessieThemeProvider>
  );
};

export const CheckmarkIcon = (props: any) => {
  return (
    <NessieThemeProvider>
      <NessieIcons.CheckmarkIcon {...props} />
    </NessieThemeProvider>
  );
};

export const ChevronDownIcon = (props: any) => {
  return (
    <NessieThemeProvider>
      <NessieIcons.ChevronDownIcon {...props} />
    </NessieThemeProvider>
  );
};

export const ChevronLeftIcon = (props: any) => {
  return (
    <NessieThemeProvider>
      <NessieIcons.ChevronLeftIcon {...props} />
    </NessieThemeProvider>
  );
};

export const ChevronRightIcon = (props: any) => {
  return (
    <NessieThemeProvider>
      <NessieIcons.ChevronRightIcon {...props} />
    </NessieThemeProvider>
  );
};

export const ChevronSmallDownIcon = (props: any) => {
  return (
    <NessieThemeProvider>
      <NessieIcons.ChevronSmallDownIcon {...props} />
    </NessieThemeProvider>
  );
};

export const ChevronSmallLeftIcon = (props: any) => {
  return (
    <NessieThemeProvider>
      <NessieIcons.ChevronSmallLeftIcon {...props} />
    </NessieThemeProvider>
  );
};

export const ChevronSmallRightIcon = (props: any) => {
  return (
    <NessieThemeProvider>
      <NessieIcons.ChevronSmallRightIcon {...props} />
    </NessieThemeProvider>
  );
};

export const ChevronSmallUpIcon = (props: any) => {
  return (
    <NessieThemeProvider>
      <NessieIcons.ChevronSmallUpIcon {...props} />
    </NessieThemeProvider>
  );
};

export const ChevronUpIcon = (props: any) => {
  return (
    <NessieThemeProvider>
      <NessieIcons.ChevronUpIcon {...props} />
    </NessieThemeProvider>
  );
};

export const ClassroomIcon = (props: any) => {
  return (
    <NessieThemeProvider>
      <NessieIcons.ClassroomIcon {...props} />
    </NessieThemeProvider>
  );
};

export const ClockIcon = (props: any) => {
  return (
    <NessieThemeProvider>
      <NessieIcons.ClockIcon {...props} />
    </NessieThemeProvider>
  );
};

export const CloseIcon = (props: any) => {
  return (
    <NessieThemeProvider>
      <NessieIcons.CloseIcon {...props} />
    </NessieThemeProvider>
  );
};

export const CloseSmallIcon = (props: any) => {
  return (
    <NessieThemeProvider>
      <NessieIcons.CloseSmallIcon {...props} />
    </NessieThemeProvider>
  );
};

export const CommentIcon = (props: any) => {
  return (
    <NessieThemeProvider>
      <NessieIcons.CommentIcon {...props} />
    </NessieThemeProvider>
  );
};

export const ComputerIcon = (props: any) => {
  return (
    <NessieThemeProvider>
      <NessieIcons.ComputerIcon {...props} />
    </NessieThemeProvider>
  );
};

export const DeleteIcon = (props: any) => {
  return (
    <NessieThemeProvider>
      <NessieIcons.DeleteIcon {...props} />
    </NessieThemeProvider>
  );
};

export const DownCarrotIcon = (props: any) => {
  return (
    <NessieThemeProvider>
      <NessieIcons.DownCarrotIcon {...props} />
    </NessieThemeProvider>
  );
};

export const DownloadIcon = (props: any) => {
  return (
    <NessieThemeProvider>
      <NessieIcons.DownloadIcon {...props} />
    </NessieThemeProvider>
  );
};

export const EyeIcon = (props: any) => {
  return (
    <NessieThemeProvider>
      <NessieIcons.EyeIcon {...props} />
    </NessieThemeProvider>
  );
};

export const FacebookIcon = (props: any) => {
  return (
    <NessieThemeProvider>
      <NessieIcons.FacebookIcon {...props} />
    </NessieThemeProvider>
  );
};

export const FileIcon = (props: any) => {
  return (
    <NessieThemeProvider>
      <NessieIcons.FileIcon {...props} />
    </NessieThemeProvider>
  );
};

export const FlagIcon = (props: any) => {
  return (
    <NessieThemeProvider>
      <NessieIcons.FlagIcon {...props} />
    </NessieThemeProvider>
  );
};

export const FolderIcon = (props: any) => {
  return (
    <NessieThemeProvider>
      <NessieIcons.FolderIcon {...props} />
    </NessieThemeProvider>
  );
};

export const FullscreenExitIcon = (props: any) => {
  return (
    <NessieThemeProvider>
      <NessieIcons.FullscreenExitIcon {...props} />
    </NessieThemeProvider>
  );
};

export const FullscreenIcon = (props: any) => {
  return (
    <NessieThemeProvider>
      <NessieIcons.FullscreenIcon {...props} />
    </NessieThemeProvider>
  );
};

export const GlobeIcon = (props: any) => {
  return (
    <NessieThemeProvider>
      <NessieIcons.GlobeIcon {...props} />
    </NessieThemeProvider>
  );
};

export const GraduationIcon = (props: any) => {
  return (
    <NessieThemeProvider>
      <NessieIcons.GraduationIcon {...props} />
    </NessieThemeProvider>
  );
};

export const HeartIcon = (props: any) => {
  return (
    <NessieThemeProvider>
      <NessieIcons.HeartIcon {...props} />
    </NessieThemeProvider>
  );
};

export const HelpIcon = (props: any) => {
  return (
    <NessieThemeProvider>
      <NessieIcons.HelpIcon {...props} />
    </NessieThemeProvider>
  );
};

export const HomeIcon = (props: any) => {
  return (
    <NessieThemeProvider>
      <NessieIcons.HomeIcon {...props} />
    </NessieThemeProvider>
  );
};

export const IdeasIcon = (props: any) => {
  return (
    <NessieThemeProvider>
      <NessieIcons.IdeasIcon {...props} />
    </NessieThemeProvider>
  );
};

export const InviteIcon = (props: any) => {
  return (
    <NessieThemeProvider>
      <NessieIcons.InviteIcon {...props} />
    </NessieThemeProvider>
  );
};

export const LightbulbIcon = (props: any) => {
  return (
    <NessieThemeProvider>
      <NessieIcons.LightbulbIcon {...props} />
    </NessieThemeProvider>
  );
};

export const LinkIcon = (props: any) => {
  return (
    <NessieThemeProvider>
      <NessieIcons.LinkIcon {...props} />
    </NessieThemeProvider>
  );
};

export const ListIcon = (props: any) => {
  return (
    <NessieThemeProvider>
      <NessieIcons.ListIcon {...props} />
    </NessieThemeProvider>
  );
};

export const LockIcon = (props: any) => {
  return (
    <NessieThemeProvider>
      <NessieIcons.LockIcon {...props} />
    </NessieThemeProvider>
  );
};

export const LoginIcon = (props: any) => {
  return (
    <NessieThemeProvider>
      <NessieIcons.LoginIcon {...props} />
    </NessieThemeProvider>
  );
};

export const LogoutIcon = (props: any) => {
  return (
    <NessieThemeProvider>
      <NessieIcons.LogoutIcon {...props} />
    </NessieThemeProvider>
  );
};

export const MailIcon = (props: any) => {
  return (
    <NessieThemeProvider>
      <NessieIcons.MailIcon {...props} />
    </NessieThemeProvider>
  );
};

export const MathIcon = (props: any) => {
  return (
    <NessieThemeProvider>
      <NessieIcons.MathIcon {...props} />
    </NessieThemeProvider>
  );
};

export const MergeIcon = (props: any) => {
  return (
    <NessieThemeProvider>
      <NessieIcons.MergeIcon {...props} />
    </NessieThemeProvider>
  );
};

export const MessagesIcon = (props: any) => {
  return (
    <NessieThemeProvider>
      <NessieIcons.MessagesIcon {...props} />
    </NessieThemeProvider>
  );
};

export const MicrophoneIcon = (props: any) => {
  return (
    <NessieThemeProvider>
      <NessieIcons.MicrophoneIcon {...props} />
    </NessieThemeProvider>
  );
};

export const MinusIcon = (props: any) => {
  return (
    <NessieThemeProvider>
      <NessieIcons.MinusIcon {...props} />
    </NessieThemeProvider>
  );
};

export const MoviesIcon = (props: any) => {
  return (
    <NessieThemeProvider>
      <NessieIcons.MoviesIcon {...props} />
    </NessieThemeProvider>
  );
};

export const MusicIcon = (props: any) => {
  return (
    <NessieThemeProvider>
      <NessieIcons.MusicIcon {...props} />
    </NessieThemeProvider>
  );
};

export const NavigationMenuIcon = (props: any) => {
  return (
    <NessieThemeProvider>
      <NessieIcons.NavigationMenuIcon {...props} />
    </NessieThemeProvider>
  );
};

export const NewsIcon = (props: any) => {
  return (
    <NessieThemeProvider>
      <NessieIcons.NewsIcon {...props} />
    </NessieThemeProvider>
  );
};

export const NotificationIcon = (props: any) => {
  return (
    <NessieThemeProvider>
      <NessieIcons.NotificationIcon {...props} />
    </NessieThemeProvider>
  );
};

export const NotificationOffIcon = (props: any) => {
  return (
    <NessieThemeProvider>
      <NessieIcons.NotificationOffIcon {...props} />
    </NessieThemeProvider>
  );
};

export const OverflowIcon = (props: any) => {
  return (
    <NessieThemeProvider>
      <NessieIcons.OverflowIcon {...props} />
    </NessieThemeProvider>
  );
};

export const PaintBrushIcon = (props: any) => {
  return (
    <NessieThemeProvider>
      <NessieIcons.PaintBrushIcon {...props} />
    </NessieThemeProvider>
  );
};

export const PaintIcon = (props: any) => {
  return (
    <NessieThemeProvider>
      <NessieIcons.PaintIcon {...props} />
    </NessieThemeProvider>
  );
};

export const PauseIcon = (props: any) => {
  return (
    <NessieThemeProvider>
      <NessieIcons.PauseIcon {...props} />
    </NessieThemeProvider>
  );
};

export const PencilIcon = (props: any) => {
  return (
    <NessieThemeProvider>
      <NessieIcons.PencilIcon {...props} />
    </NessieThemeProvider>
  );
};

export const PeopleIcon = (props: any) => {
  return (
    <NessieThemeProvider>
      <NessieIcons.PeopleIcon {...props} />
    </NessieThemeProvider>
  );
};

export const PersonIcon = (props: any) => {
  return (
    <NessieThemeProvider>
      <NessieIcons.PersonIcon {...props} />
    </NessieThemeProvider>
  );
};

export const PhoneIcon = (props: any) => {
  return (
    <NessieThemeProvider>
      <NessieIcons.PhoneIcon {...props} />
    </NessieThemeProvider>
  );
};

export const PhotoIcon = (props: any) => {
  return (
    <NessieThemeProvider>
      <NessieIcons.PhotoIcon {...props} />
    </NessieThemeProvider>
  );
};

export const PhotoLibraryIcon = (props: any) => {
  return (
    <NessieThemeProvider>
      <NessieIcons.PhotoLibraryIcon {...props} />
    </NessieThemeProvider>
  );
};

export const PlayIcon = (props: any) => {
  return (
    <NessieThemeProvider>
      <NessieIcons.PlayIcon {...props} />
    </NessieThemeProvider>
  );
};

export const PlayVideoIcon = (props: any) => {
  return (
    <NessieThemeProvider>
      <NessieIcons.PlayVideoIcon {...props} />
    </NessieThemeProvider>
  );
};

export const PlusBrandIcon = (props: any) => {
  return (
    <NessieThemeProvider>
      <NessieIcons.PlusBrandIcon {...props} />
    </NessieThemeProvider>
  );
};

export const PlusIcon = (props: any) => {
  return (
    <NessieThemeProvider>
      <NessieIcons.PlusIcon {...props} />
    </NessieThemeProvider>
  );
};

export const PortraitFemaleIcon = (props: any) => {
  return (
    <NessieThemeProvider>
      <NessieIcons.PortraitFemaleIcon {...props} />
    </NessieThemeProvider>
  );
};

export const PortraitIcon = (props: any) => {
  return (
    <NessieThemeProvider>
      <NessieIcons.PortraitIcon {...props} />
    </NessieThemeProvider>
  );
};

export const PortraitMaleIcon = (props: any) => {
  return (
    <NessieThemeProvider>
      <NessieIcons.PortraitMaleIcon {...props} />
    </NessieThemeProvider>
  );
};

export const PrintIcon = (props: any) => {
  return (
    <NessieThemeProvider>
      <NessieIcons.PrintIcon {...props} />
    </NessieThemeProvider>
  );
};

export const QrCodeIcon = (props: any) => {
  return (
    <NessieThemeProvider>
      <NessieIcons.QrCodeIcon {...props} />
    </NessieThemeProvider>
  );
};

export const QuoteIcon = (props: any) => {
  return (
    <NessieThemeProvider>
      <NessieIcons.QuoteIcon {...props} />
    </NessieThemeProvider>
  );
};

export const RepeatIcon = (props: any) => {
  return (
    <NessieThemeProvider>
      <NessieIcons.RepeatIcon {...props} />
    </NessieThemeProvider>
  );
};

export const ResetIcon = (props: any) => {
  return (
    <NessieThemeProvider>
      <NessieIcons.ResetIcon {...props} />
    </NessieThemeProvider>
  );
};

export const RewardIcon = (props: any) => {
  return (
    <NessieThemeProvider>
      <NessieIcons.RewardIcon {...props} />
    </NessieThemeProvider>
  );
};

export const RoundAlertIcon = (props: any) => {
  return (
    <NessieThemeProvider>
      <NessieIcons.RoundAlertIcon {...props} />
    </NessieThemeProvider>
  );
};

export const SchoolwideBadgeIcon = (props: any) => {
  return (
    <NessieThemeProvider>
      <NessieIcons.SchoolwideBadgeIcon {...props} />
    </NessieThemeProvider>
  );
};

export const SearchIcon = (props: any) => {
  return (
    <NessieThemeProvider>
      <NessieIcons.SearchIcon {...props} />
    </NessieThemeProvider>
  );
};

export const SelectIcon = (props: any) => {
  return (
    <NessieThemeProvider>
      <NessieIcons.SelectIcon {...props} />
    </NessieThemeProvider>
  );
};

export const SendIcon = (props: any) => {
  return (
    <NessieThemeProvider>
      <NessieIcons.SendIcon {...props} />
    </NessieThemeProvider>
  );
};

export const SettingsIcon = (props: any) => {
  return (
    <NessieThemeProvider>
      <NessieIcons.SettingsIcon {...props} />
    </NessieThemeProvider>
  );
};

export const ShuffleIcon = (props: any) => {
  return (
    <NessieThemeProvider>
      <NessieIcons.ShuffleIcon {...props} />
    </NessieThemeProvider>
  );
};

export const SkillRemoveIcon = (props: any) => {
  return (
    <NessieThemeProvider>
      <NessieIcons.SkillRemoveIcon {...props} />
    </NessieThemeProvider>
  );
};

export const SkillsAddIcon = (props: any) => {
  return (
    <NessieThemeProvider>
      <NessieIcons.SkillsAddIcon {...props} />
    </NessieThemeProvider>
  );
};

export const SkipBack10Icon = (props: any) => {
  return (
    <NessieThemeProvider>
      <NessieIcons.SkipBack10Icon {...props} />
    </NessieThemeProvider>
  );
};

export const SkipForward10Icon = (props: any) => {
  return (
    <NessieThemeProvider>
      <NessieIcons.SkipForward10Icon {...props} />
    </NessieThemeProvider>
  );
};

export const SortIcon = (props: any) => {
  return (
    <NessieThemeProvider>
      <NessieIcons.SortIcon {...props} />
    </NessieThemeProvider>
  );
};

export const StarIcon = (props: any) => {
  return (
    <NessieThemeProvider>
      <NessieIcons.StarIcon {...props} />
    </NessieThemeProvider>
  );
};

export const StickerIcon = (props: any) => {
  return (
    <NessieThemeProvider>
      <NessieIcons.StickerIcon {...props} />
    </NessieThemeProvider>
  );
};

export const StopIcon = (props: any) => {
  return (
    <NessieThemeProvider>
      <NessieIcons.StopIcon {...props} />
    </NessieThemeProvider>
  );
};

export const StoryIcon = (props: any) => {
  return (
    <NessieThemeProvider>
      <NessieIcons.StoryIcon {...props} />
    </NessieThemeProvider>
  );
};

export const StudentIcon = (props: any) => {
  return (
    <NessieThemeProvider>
      <NessieIcons.StudentIcon {...props} />
    </NessieThemeProvider>
  );
};

export const SunIcon = (props: any) => {
  return (
    <NessieThemeProvider>
      <NessieIcons.SunIcon {...props} />
    </NessieThemeProvider>
  );
};

export const ThoughtCloudIcon = (props: any) => {
  return (
    <NessieThemeProvider>
      <NessieIcons.ThoughtCloudIcon {...props} />
    </NessieThemeProvider>
  );
};

export const TimerIcon = (props: any) => {
  return (
    <NessieThemeProvider>
      <NessieIcons.TimerIcon {...props} />
    </NessieThemeProvider>
  );
};

export const ToolkitIcon = (props: any) => {
  return (
    <NessieThemeProvider>
      <NessieIcons.ToolkitIcon {...props} />
    </NessieThemeProvider>
  );
};

export const TwitterIcon = (props: any) => {
  return (
    <NessieThemeProvider>
      <NessieIcons.TwitterIcon {...props} />
    </NessieThemeProvider>
  );
};

export const UndoIcon = (props: any) => {
  return (
    <NessieThemeProvider>
      <NessieIcons.UndoIcon {...props} />
    </NessieThemeProvider>
  );
};

export const UnlockIcon = (props: any) => {
  return (
    <NessieThemeProvider>
      <NessieIcons.UnlockIcon {...props} />
    </NessieThemeProvider>
  );
};

export const VideoIcon = (props: any) => {
  return (
    <NessieThemeProvider>
      <NessieIcons.VideoIcon {...props} />
    </NessieThemeProvider>
  );
};

export const XCircleIcon = (props: any) => {
  return (
    <NessieThemeProvider>
      <NessieIcons.XCircleIcon {...props} />
    </NessieThemeProvider>
  );
};
export const ZapIcon = (props: any) => {
  return (
    <NessieThemeProvider>
      <NessieIcons.ZapIcon {...props} />
    </NessieThemeProvider>
  );
};
